<template>
  <div></div>
</template>

<script>
import Mixins from "@utils/mixins";

export default {
    name: "RedirectDevice",
    mixins: [Mixins.essentials],
    
    async created() {
        var device = await this.Helper.getDevicePlatform();

        if (device == "web"){
            this.$router.push({ name: "Home" });  
        }
        else if (device == "android"){
            window.location.href = "https://play.google.com/store/apps/details?id=com.intract.ifi";
        }
        else if (device == "ios"){
            window.location.href = "https://apps.apple.com/in/app/intract-for-institutes/id1513759986";
        }
    }
}
</script>

<style>

</style>